import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Denmark",
  "subtitle": "May 2014",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Best to rent a car if exploring beyond Copenhagen`}</li>
    </ul>
    <h3 {...{
      "id": "shopping"
    }}>{`Shopping`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Flying_Tiger_Copenhagen"
        }}>{`Tiger`}</a>{` - Danish variety store that has since expanded internationally`}</li>
    </ul>
    <h2 {...{
      "id": "copenhagen"
    }}>{`Copenhagen`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/guCPcCv6jsDr5yFeA"
        }}>{`Kødbyens Fiskebar`}</a>{` - Seafood bar`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/M2a1bXJ3PqhZhUtz8"
        }}>{`Vaffelbageren`}</a>{` - Waffles and ice cream, what could go wrong?`}</li>
      <li parentName="ul">{`Shawarma`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NspDpasUpkCuQ3zq5"
        }}>{`King’s Garden`}</a>{` & `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4X9YbfXPofkNM9We6"
        }}>{`Rosenborg Castle`}</a>{` - Great walk on a nice day`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/The_Little_Mermaid_(statue)"
        }}>{`The Little Mermaid`}</a>{` - Famous bronze statue; quite small in person`}</li>
    </ul>
    <h4 {...{
      "id": "excursions"
    }}>{`Excursions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BbkqccXVdTac9rRk9"
        }}>{`Viking Ship Museum`}</a>{` - In Roskilde`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HJw72E1AKnsTqPeg7"
        }}>{`Kronborg Castle`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Shakespeare’s Hamlet home`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/zKNF1ruxUXNggAxx5"
            }}>{`Han statue`}</a>{` inspired by “The Little Mermaid” is made of steel instead of bronze`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/niMh4vGtwRLNSSDS6"
        }}>{`Louisiana Museum of Modern Art`}</a>{` - Art museum and sculpture park north of Copenhagen`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7SYxUSdiKPVrrRXF7"
        }}>{`Frederiksborg Castle`}</a>{` - Worth a visit`}</li>
    </ul>
    <h2 {...{
      "id": "møns-klint"
    }}>{`Møns Klint`}</h2>
    <ul>
      <li parentName="ul">{`Around a 2 hour drive from copenhagen`}</li>
      <li parentName="ul">{`There's a small circular walk via staircase that's relaxing`}</li>
    </ul>
    <h2 {...{
      "id": "esbjerg"
    }}>{`Esbjerg`}</h2>
    <ul>
      <li parentName="ul">{`This town was partying through the night when I was there... unsure if that's the norm!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8cmJhZ5kCnfHHwBh9"
        }}>{`Dronning Louise`}</a>{` - Very popular spot in the main square; Tried some local fish`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NYq5pYK2FDQXif2c8"
        }}>{`Mennesket ved Havet`}</a>{` - 30ft tall statues!`}</li>
    </ul>
    <h2 {...{
      "id": "billund"
    }}>{`Billund`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bYZJ1VE8A6wJNikc8"
        }}>{`Legoland`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Slightly closer to Esbjerg than Aarhus but both were about ~1 hour drive.`}</li>
          <li parentName="ul">{`Spent around a half day here. The park is a bit small`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "aarhus"
    }}>{`Aarhus`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dengamleby.dk/en/den-gamle-by/"
        }}>{`Den Gamle By`}</a>{` - Old town area with lots of original Danish buildings since the late 1500s`}</li>
    </ul>
    <h2 {...{
      "id": "aalborg"
    }}>{`Aalborg`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.stenalinetravel.com/routes/frederikshavn-gothenburg"
        }}>{`Stena Line`}</a>{` - Ferry between Frederikshavn (Denmark) and Gothenburg (Sweden)`}</li>
    </ul>
    <h2 {...{
      "id": "skagen"
    }}>{`Skagen`}</h2>
    <ul>
      <li parentName="ul">{`Northern tip of Denmark!`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1awfaJVooppQ45wj7"
        }}>{`Wittco fisk og skaldyr`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PXhNz19VtWCEpZKf8"
        }}>{`Grenen`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Skagerrak"
            }}>{`Skagerrak`}</a>{` and `}<a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Kattegat"
            }}>{`Kattegat`}</a>{` is the collision of waves where the 2 seas. It’s visible to the naked eye and there is a strip of sand to walk on.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/c59Aj6Vjw7pJ1hPb7"
            }}>{`Sandormen tractor`}</a>{` - Very unique and fun ride!`}</li>
          <li parentName="ul">{`Parking is available for a fee`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/e95FBbnyTmNXVntN7"
        }}>{`Skagen Gray Lighthouse`}</a>{` - Right before Grenen`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Rfqcvsc8h7XEfyX58"
        }}>{`Port of Skagen`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      